import React, { useContext, useState } from 'react'
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { deleteFile, generateOpenAIFile } from '../api/FileAPI';
import FileMetaData from './FileMetaData';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PresentationContent from './PresentationContent';
import { useQueryClient } from 'react-query';
import { AuthContext } from '../context/authContext';

const SET_FILE_META_STEP = "fileMetaData"
const VIEW_PRESENTATION = "presentation"
const STEP_TEXT_DICT ={
  fileMetaData: {
    "title": "Generate a presentation with OpenAi!",
    "subTitle": "Title, categorize and tag the presentation to give OpenAi some context to work with.",
    "button": "Generate"
  },
  presentation: {
    "title": "Review the presentation generated by On Deck GPT!",
    "subTitle": "If it's not what your were looking for, you can delete it from the '...' menu."
  }
}

export default function OpenAiDialog({ open }) {
  const { user } = useContext(AuthContext);
  const theme = useTheme()
  let navigate = useNavigate()
  const queryClient = useQueryClient();
  const [fullScreen, setFullScreen] = useState(useMediaQuery(theme.breakpoints.down('md')))
  const [file, setFile] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(SET_FILE_META_STEP)

  const { enqueueSnackbar } = useSnackbar();
  const errorSnackbarOptions = {variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" }}
  //const successSnackbarOptions = {variant: "success", anchorOrigin: { horizontal: "center", vertical: "bottom" }}

  const emptyFileMetaData = {
    "title": "",
    "category": "",
    "tags": []
  }
  const [fileMetaData, setFileMetaData] = useState(emptyFileMetaData)

  const reset = () => {
    setFileMetaData(emptyFileMetaData)
    setCurrentStep(SET_FILE_META_STEP)
  }

  const handleMetaDataChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    if (key === "category"){
      setFileMetaData({
        ...fileMetaData,
        [key]: value,
        tags: []
      })
    }else{
      setFileMetaData({
        ...fileMetaData,
        [key]: value
      })
    }
  }

  const onClose = () => {
    reset()
    navigate("/")
    if (currentStep === VIEW_PRESENTATION){
      queryClient.invalidateQueries(['searchedFiles']);  //refresh
    }
  }
  
  const handleGPTClick = () => {
    setIsLoading(true)
    generateOpenAIFile(fileMetaData.title, fileMetaData.category, fileMetaData.tags)
    .then((response) => {      
      setTimeout(function() {
        setFile(response.data)
        setCurrentStep(VIEW_PRESENTATION)
        setIsLoading(false)
      }, 1500); //1.5 second
    })
    .catch((error) => {
      setIsLoading(false)
      enqueueSnackbar('Error generating presentation.', errorSnackbarOptions)
    });
  }

  const handleDeleteClick = () => {
    setIsLoading(true)
    deleteFile(file.id)
    .then((response) => {
      setIsLoading(false)
      onClose()
      queryClient.invalidateQueries(['searchedFiles']);  //refresh
    })
    .catch((error) => {
      setIsLoading(false)
      enqueueSnackbar('Error deleting file.', errorSnackbarOptions)
    });
  }

  const canDelete = () => {
    if (file?.uploadedBy  === user?.email){
      return true;
    }
    return false;
  }

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen)
  }

  const isDisabled = () => {
    if (currentStep === SET_FILE_META_STEP){
      return (!fileMetaData.title || !fileMetaData.category || fileMetaData.tags.length < 1)
    }
  }

  const onButtonClick = () => {
    if (currentStep === SET_FILE_META_STEP){
      handleGPTClick()
    }
  }

  return (    
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={"md"}
      open={open}
    >
      {!isLoading ?
        <>
          <DialogTitle id="download-dialog-title">     
            <Typography variant="h5" component="h1" sx={{fontWeight: "700"}}>
              {STEP_TEXT_DICT[currentStep].title}
            </Typography>
            <Typography variant="body1" component="h1" color="primary" gutterBottom>
              {STEP_TEXT_DICT[currentStep].subTitle}
            </Typography>
            <IconButton
              aria-label="close"
              size="small"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>

          </DialogTitle>
          <Divider/>
          <DialogContent>
            
              <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} sx={{m: "1rem"}}>
                  {currentStep === SET_FILE_META_STEP &&
                    <FileMetaData fileMetaData={fileMetaData} handleChange={handleMetaDataChange} titleLabel="Give a topic to generate a message about"/>
                  }
                  {currentStep === VIEW_PRESENTATION &&
                    <PresentationContent 
                      file={file} 
                      handleDelete={handleDeleteClick} 
                      canDelete={canDelete}
                      onToggleFullScreen={toggleFullScreen}
                      includeShare={false}
                    />
                  }
                </Grid>
                {STEP_TEXT_DICT[currentStep].button && 
                  <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
                    <Grid item xs={6}>
                      <Button
                        onClick={() => onClose()}
                        variant="outlined" 
                        sx={{
                          color: 'custom.button.cancel.text',
                          backgroundColor: 'custom.button.cancel.background',
                          borderColor: 'primary.main',
                          '&:hover': {
                            color: 'custom.button.cancel.text',
                            backgroundColor: 'custom.button.cancel.background',
                            borderColor: 'primary.main',
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item xs={6} container justifyContent="flex-end">
                      <Button
                        variant="outlined"
                        onClick={() => onButtonClick()}
                        disabled={isDisabled()}
                      >
                        {STEP_TEXT_DICT[currentStep].button}
                      </Button>
                    </Grid>
                  </Grid>
                }
              </Grid>            
          </DialogContent>
        </>
      :
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress color="secondary" size={"10rem"} sx={{m:  "10rem"}}/>
      </Grid>
    }
    </Dialog>
  )
}
