import { useEffect, useState } from "react";
import { setRequestHeader, setAPIResponseInterceptor } from "../api/APIUtil";
import ClientOAuth2 from "client-oauth2";
import { AuthContext } from "../context/authContext";
import jwtDecode from "jwt-decode";

const logoutUrl = process.env.REACT_APP_LOGOUT_URL;
const appUrl = process.env.REACT_APP_URL;
const clientId = process.env.REACT_APP_CLIENT_ID;
const accessTokenUri = process.env.REACT_APP_TOKEN_URL;
const authorizationUri = process.env.REACT_APP_AUTH_URL;
const redirectUri = process.env.REACT_APP_REDIRECT_URL;

const userAuth = new ClientOAuth2({
  clientId: clientId,
  accessTokenUri: accessTokenUri,
  authorizationUri: authorizationUri,
  redirectUri: redirectUri
});

export const LOCAL_STORAGE_USER_KEY = "OnDeckUser";

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorType, setErrorType] = useState(null);

  let fetchingInfo = false;

  const getUserFromLocalStorage = () => {
    const localState = localStorage.getItem(LOCAL_STORAGE_USER_KEY);
    const jsonState = (localState) ? JSON.parse(localState) : false;
  
    return jsonState;
  }

  const postUser = (user) => {
    if (user) {
      const info = jwtDecode(user.id_token);
      user["email"] = info?.email;
    } 
    localStorage.setItem(LOCAL_STORAGE_USER_KEY, JSON.stringify(user));
    setUser(user);
  }
  
  const getUserToken = () => {
    const jsonState = getUserFromLocalStorage();
    return jsonState["id_token"] || "";
  }
  
  const getLoginUrl = (previousPath) => {
    const uri = userAuth.code.getUri({ "state" : previousPath });
    return uri
  };

  const receiveLogin = () => {
    // Use this fetchingInfo to try and reduce the number of requests we make. We should only make one request per
    // login
    if(!fetchingInfo) {
      fetchingInfo = true;
      userAuth.code.getToken(document.URL).then(function(retToken) {          
          postUser(retToken.data);
      }, function(err) {
          console.log("Error receiving OAuth login");
          console.log(JSON.stringify(err));
          if(err["body"])
            setErrorType(err["body"]["error"]);
          setHasError(true);
      })
    }
  };

  // call this function to sign out logged in user
  const logout = () => {
    postUser(false);    
  };

  const getLogoutUrl = () => {
    let myLogoutUrl = "{0}?response_type=code&client_id={1}&redirect_uri={2}"
          .replace("{0}", logoutUrl)
          .replace("{1}", clientId)
          .replace("{2}", encodeURI(appUrl));
    
    return myLogoutUrl;
  };

  useEffect(() => {
    const initState = getUserFromLocalStorage();

    if(initState) 
        postUser(initState);

    setRequestHeader(getUserToken);
    setAPIResponseInterceptor(logout);
  // eslint-disable-next-line
  }, []);

  return (
    <AuthContext.Provider value={{user, getLoginUrl, receiveLogin, getLogoutUrl, logout, hasError, errorType }}>
      {children}
    </AuthContext.Provider>
  );
};