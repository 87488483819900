import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/authContext';

function OauthLogout() {
  const { logout, getLogoutUrl } = useContext(AuthContext);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    logout();
    window.location.replace(getLogoutUrl());
  }, [logout, getLogoutUrl]);
   
  return null;
    
}

export default OauthLogout;