import { FileUploader } from "react-drag-drop-files";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Grid, Typography } from "@mui/material";
import { useSnackbar } from 'notistack';

const fileTypes = ["pptx"];

function DragAndDropContent({file}){
  return (
    <Grid 
      container
      direction="column"
      justifyContent="center" 
      alignItems="center" 
      sx={{
        border: "2px dashed",
        borderColor: "primary.main",
        borderRadius: "0.25rem", 
        p: "6rem",
        '&:hover': {
          cursor: "pointer",
        }
      }}
    >
      <CloudUploadIcon sx={{width: "5rem", height: "4rem"}} color="primary"/>
      <Typography>
        Drag and Drop Here
      </Typography>
      <Typography>
        or
      </Typography>
      <Typography variant="h6" color="secondary">
        Browse Files
      </Typography>
      <Typography variant="caption">
        Accepted Files: .pptx only
      </Typography>
      {file &&
        <Typography variant="caption" color="secondary" sx={{mt: "2rem"}}>
        File: {file.name}
      </Typography>
      }
    </Grid>
  )
}

export default function FileDragAndDrop({ file, handleChange }) {
  const { enqueueSnackbar } = useSnackbar();
  const errorSnackbarOptions = {variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" }}
  return (
    <FileUploader
      label="Drag and Drop Here or Browse Files"
      handleChange={handleChange} 
      name="file" 
      types={fileTypes} 
      maxSize={10}
      onSizeError={(err) => enqueueSnackbar(err, errorSnackbarOptions)}
      onTypeError={(err) => enqueueSnackbar(err, errorSnackbarOptions)}
      children={<DragAndDropContent file={file}/>}
    />
  )
}