import { useContext, useEffect } from 'react';
import { Navigate } from "react-router-dom";
import { AuthContext } from '../../context/authContext';

function OauthReceive(){
  const { user, receiveLogin, hasError, errorType } = useContext(AuthContext);
  
  const params = new URLSearchParams(window.location.search)
  const redirectUrl = params.get("state") ? params.get("state") : "/"

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    receiveLogin()
  }, [receiveLogin]);
  
  if(user)
    return (<Navigate to={redirectUrl}></Navigate>);
  else if(hasError) 
    return (
        <div>
            <div>Error retrieving User Information!</div>
            { (errorType === "access_denied") ?
                <div>If you logged in using your full e-mail address, please re-login using only your Entergy username</div>
                : <div>An unknown error occurred</div>
            }
        </div>)
  else
      return (<div>Logging in...</div>);
    
}

export default OauthReceive;