import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 100,
          width: "9.25rem", 
          height: "2.5rem",
          padding: '.875rem 1.25rem .875rem 1.25rem',
          border: '.125rem solid',
          color: '#FFFFFF',
          backgroundColor: '#FF1A58',
          borderColor: '#FF1A58',
          "&.Mui-disabled": {
            color: '#FFFFFF',            
            opacity: 0.25,
          }
        },
        outlined: {
          '&:hover': {
            borderColor: '#FF1A58',
            color: '#FF1A58'
          }
        },
      }, 
    },
  }, 
  palette: {
    primary: {
      main: '#B0B0B0',
    },
    secondary: {
      main: '#FF1A58',
    },
    error: {
      main: red.A400,
    },
    custom:{
      header: "#FFFFFF",
      button: {
        cancel: {
          text: "#000000",
          background: "#FFFFFF"
        }
      },
      selected: {
        tag: "#FFBACD"
      },
      download:{
        chip: {
          text: "#FFFFFF",
          background: "#595959"
        }
      }
    }
  },
});

export default theme;