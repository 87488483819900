export const safetyTags = [
  "Storm",
  "Driving",
  "Office",
  "Mental Health",
  "Hurricane",
  "Weather",
  "Fire",
  "Nuclear",
  "Office Ergonomics",
  "Water",
  "Climate",
  "Health",
  "Chemical",
  "Summer",
  "Winter",
  "Fall",
  "Spring",
  "Outdoors",
  "General",
  "Holiday"
].sort()


export const diversityTags = [
  "General",
  "Inclusion",
  "Culture",
  "Equity",
  "Equality",
  "Gender",
  "Disability",
  "Race",
  "Ethnicity",
  "Personality",
  "Mental Health",
  "Education",
  "Relationship",
  "Holiday",
  "LGBTQ+",
  "Accessibility",
  "Multiculturalism",
  "Veteran Support"
].sort()

export const allTags = safetyTags.concat(diversityTags).sort()

export const tagsMap = {
  "Safety": safetyTags,
  "Diversity": diversityTags
}