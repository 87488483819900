import React, { useContext, useEffect, useState } from 'react'
import { deleteFile, getFileMetaData } from '../api/FileAPI';
import { Chip, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import PresentationContent from './PresentationContent';
import { useQueryClient } from 'react-query';
import { AuthContext } from '../context/authContext';

export default function DownloadDialog({ open }) {
  const { user } = useContext(AuthContext);
  let { fileId }= useParams()
  fileId ||= ""
  const location = useLocation()
  const theme = useTheme()
  let navigate = useNavigate()
  const queryClient = useQueryClient();

  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState({})
  const [fullScreen, setFullScreen] = useState(useMediaQuery(theme.breakpoints.down('md')))

  const { enqueueSnackbar } = useSnackbar();
  const errorSnackbarOptions = {variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" }}

  const onClose = () => {
    navigate("/")
    if (location.pathname?.includes("generated")){
      queryClient.invalidateQueries(['searchedFiles']);   
    }
  }

  const getFile = () =>{
    if (fileId) {
      setIsLoading(true)
      getFileMetaData(fileId)
        .then((response) => {
          if(response){
            setIsLoading(false)
            setFile(response.data)
          }
          else{
            setIsLoading(false)
            enqueueSnackbar('Error fetching file for download.', errorSnackbarOptions)
            onClose()
          }         
        })
        .catch((error) => {
          setIsLoading(false)
          enqueueSnackbar('Error fetching file for download.', errorSnackbarOptions)
          onClose()
        });
    }
  }


  const handleDeleteClick = () => {
    setIsLoading(true)
    deleteFile(fileId)
    .then((response) => {
      setIsLoading(false)
      onClose()
      queryClient.invalidateQueries(['searchedFiles']);  //refresh
    })
    .catch((error) => {
      setIsLoading(false)
      enqueueSnackbar('Error deleting file.', errorSnackbarOptions)
    });
  }

  const toggleFullScreen = () => {
    setFullScreen(!fullScreen)
  }

  const canDelete = () => {
    if (file?.uploadedBy  === user?.email){
      return true;
    }
    if (file?.uploadedBy === 'KeyString Labs' || file?.uploadedBy === 'On Deck GPT'){
      return true
    }      
    return false;
  }

  useEffect(() => {
    getFile(fileId)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileId]);
  
  return (    
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth="md"
      open={open}
      // sx={{opacity: .5}}
    >
      {!isLoading && fileId && file ?
        <>
          <DialogTitle id="download-dialog-title">     
            { !isLoading && 
              <Chip 
                label= {(file.category || "...").toUpperCase()} 
                sx={{
                  backgroundColor: "custom.download.chip.background",
                  color: "custom.download.chip.text"
                }}
              />
            }
            <IconButton
              aria-label="close"
              size="small"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseIcon />
            </IconButton>

          </DialogTitle>
          <DialogContent>
            <PresentationContent 
              file={file} 
              handleDelete={handleDeleteClick} 
              canDelete={canDelete}
              onToggleFullScreen={toggleFullScreen} 
            />
          </DialogContent>
        </>
      :
        <Grid 
          container 
          justifyContent="center" 
          alignItems="center" 
          sx={{p:  {
            xs: "5rem",
            sm: "10rem"}
          }}
          >
          <CircularProgress color="secondary" size={"10rem"}  />
        </Grid>
    }

    </Dialog>
  )
}
