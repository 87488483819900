import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import UploadIcon from '@mui/icons-material/Upload';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { useNavigate } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

export default function Header() {
  let navigate = useNavigate()
  const theme = useTheme()
  const isXsScreenSize = useMediaQuery(theme.breakpoints.down('md'))

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const handleMenuButtonClick = (navigateTo) => {
    navigate(navigateTo)
    handleClose()
  }

  return (
    <Box sx={{ flexGrow: 1, marginBottom: "6rem"}}>
      <AppBar sx={{height: "5rem", backgroundColor: "custom.header" }}>
        <Toolbar>
          <img src={`/img/EntergyLogo.svg`} alt="Entergy Logo" style={{marginTop: "1.7rem"}}/>
          {isXsScreenSize &&
          <>
            <IconButton
              sx={{
                marginLeft: "auto",
                marginTop: "1.7rem"
                }} 
              id="menu-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleMenuButtonClick("upload")}>
                <ListItemIcon>
                  <UploadIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Upload</ListItemText>
              </MenuItem>
              <MenuItem onClick={() => handleMenuButtonClick("generate")}>
                <ListItemIcon>
                  <SmartToyIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Try On Deck GPT!</ListItemText>
              </MenuItem>
            </Menu>
          </>
          }
          {!isXsScreenSize &&
          <Box sx={{
            marginLeft: "auto",
            marginTop: "1.7rem"
            }} >
            <Button 
              sx={{
                  width: "auto",
                  marginRight: "1rem",
                  }} 
              variant='outlined'
              endIcon={<SmartToyIcon />}
              onClick={() => navigate("generate")}
            >
              Try On Deck GPT!
            </Button>
            <Button 
              variant='outlined'
              endIcon={<UploadIcon />}
              onClick={() => navigate("upload")}
            >
              Upload
            </Button>
          </Box>
          }
        </Toolbar>
      </AppBar>
    </Box>
  );
}
