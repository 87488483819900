import { useContext, useEffect } from 'react';
import { AuthContext } from '../../context/authContext';


function OauthSend() {
  const { getLoginUrl } = useContext(AuthContext);
  const params = new URLSearchParams(window.location.search);
  const redirect = params.get("redirect");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    window.location.replace(getLoginUrl(redirect));
  }, [getLoginUrl, redirect]);
  
   
  return null
}

export default OauthSend;