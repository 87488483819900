import React, { useState } from 'react'
import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import FileDragAndDrop from './FileDragAndDrop';
import { uploadFile } from '../api/FileAPI';
import FileMetaData from './FileMetaData';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useQueryClient } from 'react-query';

const FILE_UPLOAD_STEP = "fileUpload"
const SET_FILE_META_STEP = "fileMetaData"
const STEP_TEXT_DICT ={
  fileUpload: {
    "title": "Add your presentation",
    "subTitle": "Upload your file.",
    "button": "Next"
  },
  fileMetaData: {
    "title": "Help others find your work",
    "subTitle": "Title, categorize and tag your work for others",
    "button": "Publish"
  }
}

export default function UploadDialog({ open }) {
  const theme = useTheme()
  let navigate = useNavigate()
  const queryClient = useQueryClient()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))

  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(FILE_UPLOAD_STEP)
  const [file, setFile] = useState(null)

  const { enqueueSnackbar } = useSnackbar();
  const errorSnackbarOptions = {variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" }}
  //const successSnackbarOptions = {variant: "success", anchorOrigin: { horizontal: "center", vertical: "bottom" }}

  const emptyFileMetaData = {
    "title": "",
    "category": "",
    "tags": []
  }
  const [fileMetaData, setFileMetaData] = useState(emptyFileMetaData)

  const reset = () => {
    setFile(null)
    setFileMetaData(emptyFileMetaData)
    setCurrentStep(FILE_UPLOAD_STEP)
  }

  const handleFileChange = (file) => {
    setFile(file)
  };

  const handleMetaDataChange = (event) => {
    let key = event.target.name;
    let value = event.target.value;

    if (key === "category"){
      setFileMetaData({
        ...fileMetaData,
        [key]: value,
        tags: []
      })
    }else{
      setFileMetaData({
        ...fileMetaData,
        [key]: value
      })
    }
  }

  const handleClose = () => {
    reset()
    navigate("/")
  }
  
  const handleUpload = () => {
    setIsLoading(true)
    uploadFile(file, fileMetaData)
      .then((response) => {
        if(response){
          setIsLoading(false)          
          //enqueueSnackbar('Successfully uploaded!', successSnackbarOptions)
          reset()
          navigate("/")
          queryClient.invalidateQueries(['searchedFiles']) //refresh after navigation
          //navigate(`/${response.data.id}`)
        }
        else{
          enqueueSnackbar('Error uploading file.', errorSnackbarOptions)
        }         
      })
      .catch((error) => {
        setIsLoading(false);
        enqueueSnackbar('Error uploading file.', errorSnackbarOptions)
      });
  }

  const isDisabled = () => {
    if (currentStep === FILE_UPLOAD_STEP){
      return !file
    }
    if (currentStep === SET_FILE_META_STEP){
      return (!fileMetaData.title || !fileMetaData.category || fileMetaData.tags.length < 1)
    }
  }

  const onButtonClick = () => {
    if (currentStep === FILE_UPLOAD_STEP){
      setCurrentStep(SET_FILE_META_STEP)
    }
    if (currentStep === SET_FILE_META_STEP){
      handleUpload()
    }
  }

  return (    
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      maxWidth={"md"}
      open={open}
    >
      <DialogTitle id="download-dialog-title">     
        <Typography variant="h5" component="h1" sx={{fontWeight: "700"}}>
          {STEP_TEXT_DICT[currentStep].title}
        </Typography>
        <Typography variant="body1" component="h1" color="primary" gutterBottom>
          {STEP_TEXT_DICT[currentStep].subTitle}
        </Typography>
        <IconButton
          aria-label="close"
          size="small"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <Divider/>
      <DialogContent>
        {!isLoading ?
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} sx={{m: "1rem"}}>
              {currentStep === FILE_UPLOAD_STEP &&
                <FileDragAndDrop file={file} handleChange={handleFileChange}/>          
              }   
              {currentStep === SET_FILE_META_STEP &&
                <FileMetaData fileMetaData={fileMetaData} handleChange={handleMetaDataChange}/>
              }
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
              <Grid item xs={6}>
                <Button
                  onClick={() => handleClose()}
                  variant="outlined" 
                  sx={{
                    color: 'custom.button.cancel.text',
                    backgroundColor: 'custom.button.cancel.background',
                    borderColor: 'primary.main',
                    '&:hover': {
                      color: 'custom.button.cancel.text',
                      backgroundColor: 'custom.button.cancel.background',
                      borderColor: 'primary.main',
                    }
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item xs={6} container justifyContent="flex-end">
                <Button
                  variant="outlined"
                  onClick={() => onButtonClick()}
                  disabled={isDisabled()}
                >
                  {STEP_TEXT_DICT[currentStep].button}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        :
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress color="secondary" size={"10rem"} sx={{m:  "10rem"}}/>
          </Grid>
        }
      </DialogContent>
    </Dialog>
  )
}
