import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";
import { useContext } from "react";

export const ProtectedRoute = ({ children }) => {
  const { user } = useContext(AuthContext);
  if (!user) {
    // user is not authenticated
    const url = `/login?redirect=${window.location.pathname}`;
    return <Navigate to={url} />;
  }
  return children;
};