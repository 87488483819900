import { Link, Typography } from '@mui/material';

function Copyright() {
  return (
    <Typography variant="body2" color="text.primary" align="center" sx={{marginBottom: "2rem", marginTop: "2rem"}}>
      {'Copyright © '}
      <Link color="inherit" href="https://entergy.com/">
        Entergy
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default Copyright;