import { Box, CircularProgress, Grid, ImageList, ImageListItem, ImageListItemBar, InputAdornment, Pagination, Tab, Tabs, TextField, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { allTags, tagsMap } from '../constants/tags';
import { useState } from 'react';
import { categories } from '../constants/categories';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Outlet, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import useSearchFiles from '../hooks/useSearchFiles';

let searchTimer;
const SEARCH_DELAY = 300;
const PAGE_SIZE = 10;

function Home() {
  let navigate = useNavigate()

  const [tagIdx, setTagIdx] = useState(0)
  const [page, setPage] = useState(1)
  const [pageStart, setPageStart] = useState(0)
  const [displayedTags, setDisplayedTags] = useState(allTags)  
  const [categoryIdx, setCategoryIdx] = useState(0)
  const [searchValue, setSearchValue] = useState("")
  const [selectedTag, setSelectedTag] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("")

  const theme = useTheme();
  const xsSize= useMediaQuery(theme.breakpoints.down('md'));

  const { enqueueSnackbar } = useSnackbar();
  const errorSnackbarOptions = {variant: "error", anchorOrigin: { horizontal: "center", vertical: "bottom" }}

  const { isFetching, isError, data } = useSearchFiles(searchValue, selectedCategory, selectedTag, pageStart, PAGE_SIZE)
  const totalItems = data?.total ?? 0
  const files = data?.files ?? []

  const fileSearchTimeout = (callback, limit) => {
    window.clearTimeout(searchTimer);
    searchTimer = window.setTimeout(callback, limit);
  }

  const resetPage = () => {
    setPage(1)
    setPageStart(0)
  }

  const throttleSearchChange = (event) => {
    fileSearchTimeout(
      () => {
        setSearchValue(event.target.value)
        resetPage()
      }, SEARCH_DELAY);
  }

  const handleCategoryChange = (event, idx) => {
    setCategoryIdx(idx)
    setSelectedCategory(categories[idx-1])
    setDisplayedTags(idx ? tagsMap[categories[idx - 1]] : allTags)
    setTagIdx(0)
    setSelectedTag("")
    resetPage()
  }

  const handleTagChange = (event, idx) => {
    const tagValue = categoryIdx ? displayedTags[idx - 1] : allTags[idx -1]
    setTagIdx(idx)
    setSelectedTag(tagValue)
    resetPage()
  }

  const handlePageChange = (event, val) => {
    setPage(val)
    setPageStart(val === 1 ? 0 : (val - 1) * PAGE_SIZE)
  }

  if (isError) {
    enqueueSnackbar('Error fetching files.', errorSnackbarOptions)
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container alignItems="center" rowSpacing={6}>
        <Grid item xs={6}>
          <Typography variant="h4" component="h1" gutterBottom sx={{fontWeight: "700"}}>
            You're On Deck!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="outlined-search"
            fullWidth
            defaultValue={searchValue}
            placeholder="Search..."
            variant='outlined'
            onChange={throttleSearchChange}
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon/></InputAdornment>,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={categoryIdx}
            onChange={handleCategoryChange}
            centered
            aria-label="category"
            name="category"
            indicatorColor="secondary"
          >
            <Tab label="All Categories" />
            {categories.map((cat, index) => (
              <Tab key={index} label={cat} />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tagIdx}
            onChange={handleTagChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="tags"
            indicatorColor="secondary"
          >
            <Tab label="All Tags" />
            {displayedTags.map((tag, index) => (
              <Tab key={index} label={tag} />
            ))}
          </Tabs>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" component="h1" gutterBottom sx={{fontWeight: "700"}}>
            Discover
          </Typography>
          {isFetching && 
            <Grid container item justifyContent="center" sx={{marginTop: "8rem", marginBottom: "8rem"}}>
              <CircularProgress color="secondary" size={100} />
            </Grid>
          }
          {!isFetching &&            
            <ImageList cols={xsSize ? 1 : 2} gap={8}>
              {files.map((item, index) => (
                <ImageListItem 
                  key={index} 
                  onClick={() => navigate(`/${item.id}`)}
                  sx={{'&:hover': {
                    cursor: "pointer",
                  }}}
                  >
                  <img
                    style={{ border: '1px solid', borderColor: "primary.main", }}
                    src={`/api/file/download/${item.id}?type=preview`}
                    alt={item.title}
                    loading="lazy"
                  />
                  <ImageListItemBar
                    title={
                      <Typography variant="h6">
                        {item.title}
                      </Typography>
                    }
                    subtitle={
                      <Typography variant="subtitle1">
                        by: {item.uploadedBy === "system" ? "KeyString Labs" : item.uploadedBy}
                      </Typography>
                    }
                    position="below"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          }
        </Grid>
        <Grid item xs={12} container justifyContent="center" alignItems="center">
          {totalItems>0 &&
            <Pagination
              count={totalItems % PAGE_SIZE === 0 ? totalItems/PAGE_SIZE : parseInt(totalItems/PAGE_SIZE) + 1} 
              showFirstButton 
              showLastButton
              page={page}
              onChange={handlePageChange}
            />
          }
          <Typography component="h1" sx={{marginLeft: "1rem"}}>
            Total: {totalItems}
          </Typography>
        </Grid>
      </Grid>
      {/* react router needed for routing to dialog popups (upload and download) */}
      <Outlet />
    </Box>
  );
}

export default Home;
