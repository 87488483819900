import axios from 'axios';

const APIUtils = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const setRequestHeader = (tokenCallback) => {
  APIUtils.interceptors.request.use(req => {
    const token = tokenCallback();
    if(token)
      req.headers.Authorization = `Bearer ${token}`;
    else
      req.headers.Authorization = "";

    return req;
  });
}

const setAPIResponseInterceptor = (logoutCallback) => {
  APIUtils.interceptors.response.use((res) => {
          return res;
      },
      async (err) => {
          if(err.response?.status === 401) {
            logoutCallback();
          }            
          return Promise.reject(err);
      }
  );
}

export {
  setRequestHeader,
  setAPIResponseInterceptor,
  APIUtils
}