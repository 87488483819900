import { APIUtils } from "./APIUtil"

const searchFiles = (searchValue, category, tag, pageStart, pageSize) => {

  let matches = []; 
  let filterTerms = []; 

  if(searchValue){
    let searchObject = {'value': searchValue, 'fields' : ['title', 'category', 'tags', 'fileName', 'uploadedBy']}
    matches.push(searchObject)
  }

  if(tag){
    let selectedTagObject = {'value': tag, 'fields' : ['tags']}
    matches.push(selectedTagObject)
  }

  if(category){
    let categoryObject = {"field" : "category", "values" : [category]}
    filterTerms.push(categoryObject)
  }

  let payload = {
    "matches": matches,
    "filterTerms": filterTerms,
    "sort": "uploadedDate:desc:date",
    "pageSize" : pageSize,
    "pageStart" : pageStart
  }
  
  return APIUtils.post(`search/files`, payload)
}

const getFileMetaData = (id) => {
  return APIUtils.get(`file/metadata/${id}`)
}

const updateFileMetaData = (id, updated) => {
  return APIUtils.put(`file/metadata/${id}`, updated)
}

const uploadFile = (file, fileMetaData) => {
  let formData = new FormData();
  const headers = {
    headers: {
      "Content-Type": "multipart/form-data",
    }
  }

  formData.append("file", file)
  const postUrl = `file/upload?title=${fileMetaData.title}&category=${fileMetaData.category}&tags=${JSON.stringify(fileMetaData.tags)}`

  return APIUtils.post(postUrl, formData, headers)
  
}

const deleteFile = (id) => {
    return APIUtils.delete(`file/delete/${id}`)
}

const generateOpenAIFile = (title, category, tags) => {
  const data = {
    "title": title,
    "category": category,
    "tags": tags
  }
  return APIUtils.post('generate/file', data)
}

export {
  searchFiles,
  getFileMetaData,
  updateFileMetaData,
  uploadFile,
  deleteFile,
  generateOpenAIFile
};
