import React, { useState } from 'react'
import { Button, Grid, IconButton, ImageList, ImageListItem, Menu, MenuItem, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import IosShareIcon from '@mui/icons-material/IosShare';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useSnackbar } from 'notistack';
import useWidth from '../hooks/useWidth';

export default function PresentationContent({ file, handleDelete, canDelete, onToggleFullScreen, includeShare=true }) {
  const screenWidth = useWidth()
  const [anchorEl, setAnchorEl] = useState(null)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const successSnackbarOptions = {variant: "success", anchorOrigin: { horizontal: "center", vertical: "bottom" }}

  const downloadFile = () => {
    let a = document.createElement("a");
    a.href = `/api/file/download/${file.id}`;
    a.click();
  }

  const openActionMenu = Boolean(anchorEl);

  const handleActionMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleActionMenuClose = () => {
    setAnchorEl(null);
  }

  const handleShareClick = () => {
    navigator.clipboard.writeText(window.location.href).then(function() {
      enqueueSnackbar('Link copied to clipboard!', successSnackbarOptions)
      handleActionMenuClose()
    });
  }

  const deleteAction = snackbarId => (
    <>
      <IconButton onClick={() => {closeSnackbar(snackbarId); handleDelete()}}>
        <CheckIcon sx={{color: "white"}}/>
      </IconButton>
      <IconButton onClick={() => { closeSnackbar(snackbarId); handleActionMenuClose() }}>
        <CloseIcon sx={{color: "white"}}/>
      </IconButton>
    </>
  );

  const showDelete = () => (
    enqueueSnackbar('Are you sure?', {
      anchorOrigin: { horizontal: "right", vertical: "bottom" },
      preventDuplicate: true,
      action: deleteAction,
    })
  )
  
  return (    
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} container justifyContent="center" alignItems="center" sx={{mt: "2rem", mb: "2rem"}}>
        <ImageList cols={1}>
          <ImageListItem>
            <img
              style={{ border: '1px solid', borderColor: "primary" }}
              src={`/api/file/download/${file.id}?type=preview`}
              alt={file.title}
            />
          </ImageListItem>
        </ImageList>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end" alignItems="center">
        <Grid item xs={4}>
          <Typography variant="h6">
            {file.title}
          </Typography>
          <Typography variant="subtitle1">
            by: {file.uploadedBy === "system" ? "KeyString Labs" : file.uploadedBy}
          </Typography>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" alignItems="center">
          <Button
            onClick={() => downloadFile()}
            variant="outlined" 
            endIcon={<DownloadIcon />}
          >
            Download
          </Button>
          <IconButton
            id="action-menu"
            aria-controls={openActionMenu ? 'action-menu-button' : undefined}
            aria-haspopup="true"
            aria-expanded={openActionMenu ? 'true' : undefined}
            onClick={handleActionMenuClick}
            sx={{border: ".125rem solid", borderColor: "primary", ml: ".5rem"}}
            height="2.5rem"
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="action-menu"
            anchorEl={anchorEl}
            open={openActionMenu}
            onClose={handleActionMenuClose}
            MenuListProps={{
              'aria-labelledby': 'action-menu-button',
            }}
          >
            {screenWidth !== "xs" && onToggleFullScreen &&
              <MenuItem onClick={() => onToggleFullScreen()}>
                <FullscreenIcon sx={{mr: ".5rem"}}/> Full Screen
              </MenuItem>
            }
            {includeShare &&
            <MenuItem onClick={() => handleShareClick()}>
              <IosShareIcon sx={{mr: ".5rem"}}/> Share
            </MenuItem>
            }
            {canDelete() &&
              <MenuItem onClick={() => showDelete()}>
                <DeleteOutlinedIcon sx={{mr: ".5rem"}}/> Delete
              </MenuItem>
            }
          </Menu>
        </Grid>
      </Grid>
    </Grid>
  )
}
