import { useQuery } from 'react-query';
import { searchFiles } from '../api/FileAPI';

export default function useSearchFiles(searchValue, category, tag, pageStart, pageSize) {
  return useQuery(['searchedFiles', searchValue, category, tag, pageStart, pageSize], async () => {
    const { data } = await searchFiles(searchValue, category, tag, pageStart, pageSize)
    .then((response) => {
      if(response){
        let data = {}
        data["total"] = response?.data?.hits?.total?.value;
        let hits = response?.data?.hits?.hits
        data["files"] = hits ? hits.map((hit) => hit._source) : []
        return {data: data}
      }    
    })
    return data;
  }, {enabled: true});
}
