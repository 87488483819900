import { useState } from "react";
import { Box, Chip, Grid, ListItem, Tab, Tabs, TextField, Typography } from "@mui/material";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ClearIcon from '@mui/icons-material/Clear';
import { categories } from "../constants/categories";
import { tagsMap } from "../constants/tags";

export default function FileMetaData({ fileMetaData, handleChange, titleLabel="Give your presentation a title" }) {
  const [displayedTags, setDisplayedTags] = useState([])

  const updateFileMetaData = (fieldName, value) => {
    const updateInfo = {
      target: {
        name: fieldName,
        value: value
      }
    }
    handleChange(updateInfo)
  }

  const updateCategory = (event, categoryIndex) => {
    updateFileMetaData("category", categories[categoryIndex])
    setDisplayedTags(tagsMap[categories[categoryIndex]])
  }

  const addTag = (tagName) => {
    if (!fileMetaData.tags.includes(tagName)){
      const tags = [...fileMetaData.tags]
      tags.push(tagName)
      updateFileMetaData("tags", tags)
    }
  }

  const removeTag = (tagName) => {
    if (fileMetaData.tags.includes(tagName)){
      let tags = [...fileMetaData.tags]
      tags = tags.filter(tag => tag !== tagName);
      updateFileMetaData("tags", tags)
    }
  }

  return (
    <Grid container rowSpacing={3}>
      <Grid item xs={12}>
        <Typography variant="button">
          {titleLabel}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TextField 
          fullWidth
          aria-label="title"
          name="title" 
          id="title" 
          variant='outlined'
          value={fileMetaData.title}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="button">
          Choose a category
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Tabs
          value={categories.includes(fileMetaData.category) ? categories.indexOf(fileMetaData.category) : null}
          onChange={updateCategory}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="category"
          name="category"
          indicatorColor="secondary"
        >
          {categories.map((cat, index) => (
            <Tab key={index} label={cat} />
          ))}
        </Tabs>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="button">
          Choose tags
        </Typography>
        <Typography variant="body1" color="primary">
          Choose as many fixed tags as you like
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{ 
            border: '1px solid primary.main',
            lineHeight: "3rem",
            p: "1rem",
          }}
        >
          {displayedTags.length === 0 && 
            <Typography variant="body1" color="error">You must select a category in order to choose tags.</Typography>
          }
          {displayedTags.map((tag, index) => {
            let icon;
            let onDelete;
            let variant = "outlined"
            let sx = {borderRadius: "0.5rem"}
            if (fileMetaData?.tags?.includes(tag)) {
              icon = <CheckCircleOutlineIcon color="secondary"/>;
              onDelete = () => removeTag(tag)
              variant = "filled"
              sx = {
                borderRadius: "0.5rem", 
                borderColor: "custom.selected.tag", 
                backgroundColor: "custom.selected.tag", 
                '&:hover': {
                  borderColor: "custom.selected.tag",
                  backgroundColor: "custom.selected.tag",
                }
              }
            }

            return (
              <ListItem key={index} sx={{display: "inline", p: ".5rem"}} >
                <Chip
                  sx={sx}
                  variant={variant}
                  name={tag}
                  icon={icon}
                  label={tag}
                  onClick={() => addTag(tag)}
                  deleteIcon={<ClearIcon />}
                  onDelete={onDelete}
                />
              </ListItem>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  )
}