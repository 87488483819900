import { Container } from '@mui/material';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import Copyright from './components/Copyright';
import DownloadDialog from './components/DownloadDialog';
import Header from './components/Header';
import UploadDialog from './components/UploadDialog';
import Home from './pages/Home';
import OpenAiDialog from './components/OpenAiDialog';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ProtectedRoute } from './components/ProtectedRoute';
import OauthReceive from './components/oauth/OauthReceive';
import OauthSend from './components/oauth/OauthSend';
import OauthLogout from './components/oauth/OauthLogout';
import { AuthContextProvider } from './providers/AuthContextProvider';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // globally default to 60 seconds
      staleTime: 1000 * 60,
      refetchOnWindowFocus: false
    },
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>      
      <Container>
        <BrowserRouter>    
          <AuthContextProvider>  
            <Routes>
              <Route path="/authcallback" element={(<OauthReceive />)} />
              <Route path="/login" element={(<OauthSend />)} />
              <Route path="/logout" element={(<OauthLogout />)} />
              <Route 
                path="/" 
                element={(
                  <ProtectedRoute>
                    <Header/>
                    <Home/>
                    <Copyright/>
                  </ProtectedRoute>
                )}
              >
                <Route path="upload" element={(<UploadDialog open={true} />)}/>
                <Route path="generate" element={(<OpenAiDialog open={true} />)}/>
                <Route path=":fileId" element={(<DownloadDialog open={true} />)}/>
              </Route>
            </Routes>
          </AuthContextProvider>
        </BrowserRouter>
      </Container>
    </QueryClientProvider>
  );
}

export default App;
